import { ChangeEvent, Fragment, useEffect, useState } from "react";
import ReactPixel from "react-facebook-pixel";
import { useApiWorker } from "../../Utilities/CommonHooks";
import {
  Col,
  Container,
  Form,
  Row,
  Alert,
  Modal,
  Button,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { format, addDays } from "date-fns";
import "./CheckoutForm.scss";
import {
  SubscriptionVM,
  TaxVM,
  CustomerVM,
  BillingAddressVM,
  PromoCodeVM,
  CountryVM,
} from "./Models/index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Icon from "@fortawesome/free-brands-svg-icons";
import * as FreeIcon from "@fortawesome/free-solid-svg-icons";
import * as Icons from "@fortawesome/fontawesome-common-types";
import classnames from "classnames";
import {
  useElements,
  useStripe,
  CardCvcElement,
  CardNumberElement,
  CardExpiryElement,
} from "@stripe/react-stripe-js";
import ValidationErrors from "../Errors/ValidationErrors";
import { useGlobalState } from "../../Context";
import { SkinnedButton } from "../../Components/Form/Buttons";
import {
  Helper,
  redBorder,
  blueBorder,
  darkBlue,
  white,
  gray,
  red,
  basePathName,
} from "../../Utilities/HelperData";
import { ErrorMessage, LoadingSection } from "../../Components/Display";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { Prompt } from "react-router";
import * as Yup from "yup";
import { useCustomForm } from "../../Utilities/UseCustomForm";
import { DataDropDown, TextInput } from "../../Components/Form/Inputs";
import { SetupIntentResult, Stripe } from "@stripe/stripe-js";
import axios from "axios";
import { CoreEnum } from "../Shared/Models/SharedModels";
import { useGlobalDispatch, GlobalActionTypes } from "../../Context";
import authService from "../../Components/ApiAuthorization/AuthorizeService";
import { constants } from "../../Utilities/Constants";

interface IProps {
  selectedPlan: any;
}

interface IFields {
  id: any;
  type: any;
  placeholder: any;
  autoComplete: any;
  value: any;
  className: any;
  onChange: any;
  onBlur: any;
  name: string;
  error?: string;
  maxLength: number;
  disabled?: boolean;
}

const CARD_OPTIONS = {
  iconStyle: "solid" as "solid",
  style: {
    base: {
      iconColor: "#4b5f9c",
      color: "#79829f",
      fontWeight: 500,
      fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
      fontSize: "14px",
      fontSmoothing: "antialiased",
      ":-webkit-autofill": {
        color: "#79829f",
      },
      "::placeholder": {
        color: "#79829f",
      },
    },
    invalid: {
      iconColor: "#ffc7ee",
      color: "#79829f",
    },
  },
};

const CARD_EXPIRY_OPTIONS = {
  style: {
    base: {
      iconColor: "#4b5f9c",
      color: "#79829f",
      fontWeight: 500,
      fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
      fontSize: "14px",
      fontSmoothing: "antialiased",
      ":-webkit-autofill": {
        color: "#79829f",
      },
      "::placeholder": {
        color: "#79829f",
      },
    },
    invalid: {
      iconColor: "#ffc7ee",
      color: "#79829f",
    },
  },
};

const Field = ({
  id,
  type,
  placeholder,
  autoComplete,
  value,
  onChange,
  onBlur,
  name,
  error = "",
  className,
  maxLength,
  disabled = false,
}: IFields) => (
  <div className="form-group">
    <input
      id={id}
      className={className}
      onBlur={onBlur}
      type={type}
      name={name}
      placeholder={placeholder}
      autoComplete={autoComplete}
      value={value}
      onChange={onChange}
      maxLength={maxLength}
      disabled={disabled}
    />
    <ErrorMessage visible={isNotUndefined(error)} message={error} />
  </div>
);

class BillingFormValues {
  address: string = "";
  city: string = "";
  suite: string = "";
  state: string = "";
  zip: string = "";
  country: CoreEnum = new CoreEnum("US", "United States");

  constructor() {}
}

const validationSchema = Yup.object({
  isStateFreeform: Yup.boolean(),
  isStateRequired: Yup.boolean(),
  isPostalCodeRequired: Yup.boolean(),
  address: Yup.string()
    .max(255, "The length of address must be 255 characters or fewer")
    .required(""),
  suite: Yup.string().max(10, "Suite must be 10 characters or fewer"),
  city: Yup.string()
    .max(100, "City must be 100 characters or fewer")
    .required(""),
  country: Yup.object()
    .typeError("")
    .shape({
      label: Yup.string().required("country"),
    }),
  state: Yup.object()
    .nullable()
    .when("isStateFreeform", {
      is: false,
      then: Yup.object()
        .typeError("")
        .shape({
          label: Yup.string().required(""),
        }),
    }),
  province: Yup.string().when(["isStateFreeform", "isStateRequired"], {
    is: true,
    then: Yup.string().required(""),
  }),
  zip: Yup.string().when("isPostalCodeRequired", {
    is: true,
    then: Yup.string().required(""),
  }),
});

let CancelToken = axios.CancelToken;
let cancel: any;

export const CheckoutForm = ({ selectedPlan }: IProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [creditCardIcon, setCreditCardIcon] = useState<Icons.IconDefinition>(
    FreeIcon.faCreditCard
  );
  const messageForExistingAccount =
    "Welcome! You can start creating your public profile";
  const US = "US";
  const CA = "CA";
  const {
    currentUser,
    customerSupportContactPhoneNumber,
    customerSupportContactEmail,
  } = useGlobalState();
  const history = useHistory();
  const apiWorker = useApiWorker();
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState<any>(null);
  const [cardErrors, setCardErrors] = useState<any>({});
  // If the address is invalid the requirement is that address fields should be highlighted. Another
  // state hook is used because formErrors is client side and can potentially get cleared on the blur event
  const [stripeTaxAddressErrors, setStripeTaxAddressErrors] = useState<any>({});
  const [skipBillingAddressValidation, setSkipBillingAddressValidation] =
    useState(false);
  const [processing, setProcessing] = useState(false);
  const [taxesHaveBeenCalculatedOnce, setTaxesHaveBeenCalculatedOnce] =
    useState(false);
  const [isCalculatingTaxes, setIsCalculatingTaxes] = useState(false);
  const [taxInformation, setTaxInformation] = useState<TaxVM>();
  const [visibleStates, setVisibleStates] = useState<CoreEnum[]>([]);
  const [states, setStates] = useState<[string, CoreEnum[]][]>([]);
  const [displayTimeoutError, setDisplayTimeoutError] = useState(false);
  const [selectedExistingPaymentMethod, setSelectedExistingPaymentMethod] =
    useState<CoreEnum | null>(null);
  const [preventBrowserNavigation, setPreventBrowserBlockNavigation] =
    useState(false);
  const initialValues = new BillingFormValues();
  const [promoCodeString, setPromoCodeString] = useState<string>("");
  const [promoCode, setPromoCode] = useState<PromoCodeVM>(new PromoCodeVM());
  const [promoCodeNote, setPromoCodeNote] = useState<string>("");
  const timeoutError = `Your subscription was created but the payment is not yet processed. Please contact our support team to check the status of your payment. You can call us ${customerSupportContactPhoneNumber} or email us at ${customerSupportContactEmail}.`;
  const genericError = `There was an unexpected error with your request, please try again. If further issues persist please contact customer service at ${customerSupportContactEmail} or ${customerSupportContactPhoneNumber}.`;
  const genericIssueError = `There was an issue processing your subscription, please try again. If further issues persist please contact customer service at ${customerSupportContactEmail} or ${customerSupportContactPhoneNumber}.`;
  const [modifiedAddressModal, setModifiedAddressModal] = useState(false);
  const toggleModifiedAddressModal = () =>
    setModifiedAddressModal(!modifiedAddressModal);
  const dispatch = useGlobalDispatch();
  const campaignPath =
    window.sessionStorage.getItem(constants.CampaignSession) ?? "";
  const [countriesCoreEnum, setCountriesCoreEnum] = useState<CoreEnum[]>([]);
  const [countries, setCountries] = useState<CountryVM[]>([]);
  const [isStateRequired, setIsStateRequired] = useState(true);
  const [isPostalCodeRequired, setIsPostalCodeRequired] = useState(true);
  const [isStateFreeform, setIsStateFreeform] = useState(false);

  useEffect(() => {
    if (currentUser) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      setIsLoading(false);
    }
  }, [currentUser]);

  useEffect(() => {
    window.onbeforeunload = confirmExit;
    function confirmExit() {
      return "Leave site? Changes you made may not be saved.";
    }

    loadCountriesList(selectedPlan.countries);

    let transformedStates: [string, CoreEnum[]][] = [];
    for (let country of selectedPlan.countries) {
      let currentStates = selectedPlan.states
        .filter((y: any) => y.value === country.isoCode)
        .map((x: CoreEnum) => {
          let element = new CoreEnum();
          element.label = x.label;
          element.value = x.label;

          return element;
        });
      if (currentStates.length > 0) {
        transformedStates.push([country.isoCode, currentStates]);
      }
    }
    setStates(transformedStates);
    if (transformedStates.length > 0) {
      setVisibleStates(transformedStates!.find((x: any) => x[0] === US)![1]);
    } else {
      setVisibleStates([]);
    }
  }, []);

  const setBillingAddress = async (): Promise<boolean> => {
    const result = await apiWorker.post<boolean>(
      `${basePathName}/api/billing/setbillingaddress`,
      {
        isOverwriteExisting: false,
        city: values?.city || values?.values?.city,
        address:
          (values?.address || values?.values?.address) +
          (values?.suite || values?.values?.suite
            ? " " + (values?.suite || values?.values?.suite)
            : ""),
        zip: values?.zip || values?.values?.zip,
        state: !isStateFreeform
          ? values?.state.label || values?.values?.state.label
          : values?.province || values?.values?.province,
        country: values?.country.value || values?.values?.country.value,
      },
      {}
    );
    return result.data;
  };

  const createSubscription = async (
    paymentMethodId: string,
    id: string,
    campaignId: string,
    promoCode: string,
    productName: string
  ): Promise<boolean> => {
    setDisplayTimeoutError(false);
    let exceptionThrown = false;
    let doRedirect = false;
    const result = await apiWorker
      .post<SubscriptionVM>(
        `${basePathName}/api/billing/createsubscription`,
        {
          paymentMethodId: paymentMethodId,
          productId: id,
          campaignId: campaignId,
          promoCode: promoCode,
          productName: productName,
        },
        {}
      )
      .catch((err) => {
        setPreventBrowserBlockNavigation(false);
        displayError(genericError);
        exceptionThrown = true;
      });

    if (exceptionThrown) {
      return false;
    }

    // If the card is declined, display an error to the user.
    if (!result || !result.data) {
      setPreventBrowserBlockNavigation(false);
      displayError(
        `There was an issue processing your subscription, please try again. If further issues persist please contact customer service at ${customerSupportContactEmail} or ${customerSupportContactPhoneNumber}`
      );
      return false;
    }
    if (result && result.data && result.data.error) {
      setPreventBrowserBlockNavigation(false);
      // The card had an error when trying to attach it to a customer.
      displayError(result.data.error);
      return false;
    }

    try {
      let firstPass = {
        paymentMethodId: paymentMethodId,
        id: id,
        subscription: result && result.data,
        promoCode: promoCode,
      };

      // Some payment methods require a customer to be on session
      // to complete the payment process. Check the status of the
      // payment intent to handle these actions.
      let secondPass = await handlePaymentThatRequiresCustomerAction({
        subscription: firstPass.subscription,
        invoice: null,
        id: firstPass.id,
        paymentMethodId: firstPass.paymentMethodId,
        isRetry: null,
      });
      // If attaching this card to a Customer object succeeds,
      // but attempts to charge the customer fail, you
      // get a requires_payment_method error.
      if (secondPass == null) return false;
      doRedirect = await onSubscriptionComplete({
        subscription: secondPass.subscription,
        invoice: null,
        id: secondPass.id,
        paymentMethodId: secondPass.paymentMethodId,
        isRetry: null,
      });
    } catch (ex: any) {
      displayError(ex && ex.message ? ex.message : ex);
      return false;
    }
    return doRedirect;
  };

  const loadCountriesList = (countries: any) => {
    const countriesList: CoreEnum[] = countries.map((x: CountryVM) => {
      let element = new CoreEnum();
      element.label = x.name;
      element.value = x.isoCode;
      return element;
    });
    setCountriesCoreEnum(countriesList);
    setCountries(countries);
  };

  const setBrandIcon = (brand: string) => {
    if (brand === "visa") {
      setCreditCardIcon(Icon.faCcVisa);
    } else if (brand === "mastercard") {
      setCreditCardIcon(Icon.faCcMastercard);
    } else if (brand === "amex") {
      setCreditCardIcon(Icon.faCcAmex);
    } else if (brand === "discover") {
      setCreditCardIcon(Icon.faCcDiscover);
    } else if (brand === "diners") {
      setCreditCardIcon(Icon.faCcDinersClub);
    } else if (brand === "jcb") {
      setCreditCardIcon(Icon.faCcJcb);
    } else {
      setCreditCardIcon(FreeIcon.faCreditCard);
    }
  };

  function handlePaymentThatRequiresCustomerAction({
    invoice,
    subscription,
    id,
    paymentMethodId,
    isRetry,
  }: any) {
    if (
      subscription &&
      (subscription.status === "active" || subscription.status === "trialing")
    ) {
      // Subscription is active, no customer actions required.
      return {
        id: id,
        subscription: subscription,
        invoice: null,
        paymentMethodId: paymentMethodId,
      };
    }

    // If it's a first payment attempt, the payment intent is on the subscription latest invoice.
    // If it's a retry, the payment intent will be on the invoice itself.
    let paymentIntent = invoice
      ? invoice.payment_intent
      : subscription.latest_invoice.payment_intent;

    if (
      paymentIntent.status === "requires_action" ||
      (isRetry === true && paymentIntent.status === "requires_payment_method")
    ) {
      if (stripe == null) return;

      return stripe
        .confirmCardPayment(paymentIntent.client_secret, {
          payment_method: paymentMethodId,
        })
        .then((result) => {
          if (result.error) {
            // The card was declined (i.e. insufficient funds, card has expired, etc).
            throw result;
          } else {
            if (result.paymentIntent.status === "succeeded") {
              subscription.status = "active";
              return {
                id: id,
                subscription: subscription,
                invoice: invoice,
                paymentMethodId: paymentMethodId,
              };
            }
          }
        })
        .catch((err) => {
          displayError(
            err && err.message
              ? err.message
              : err && err.error && err.error.message
              ? err.error.message
              : "There was an issue processing your subscription with the credit card provided. Please try again with a different credit card"
          );
        });
    } else {
      // No customer action needed.
      return {
        id: id,
        subscription: subscription,
        invoice: null,
        paymentMethodId: paymentMethodId,
      };
    }
  }

  const onSubscriptionComplete = async (result: any): Promise<boolean> => {
    let doRedirect = false;

    ReactPixel.track("Purchase", {
      currency: "USD",
      value: selectedPlan.isFreeTrial
        ? 0
        : Math.round(
            ((promoCode.promoCodeApplied
              ? promoCode.priceAfterPromoCode
              : selectedPlan.price) /
              100 +
              (taxInformation?.total || 0) / 100) *
              100
          ) / 100,
      content_name: selectedPlan.name,
      contents: [
        {
          id: selectedPlan.productId,
          quantity: 1,
        },
      ],
    });

    window.gtag("event", "purchase", {
      non_interaction: true,
      transaction_id: result.subscription.id,
      value: selectedPlan.isFreeTrial
        ? 0
        : Math.round(
            ((promoCode.promoCodeApplied
              ? promoCode.priceAfterPromoCode
              : selectedPlan.price) /
              100 +
              (taxInformation?.total || 0) / 100) *
              100
          ) / 100,
      currency: "USD",
      coupon: promoCode.promoCode,
      tax: selectedPlan.isFreeTrial
        ? 0
        : Math.round(
            (taxesHaveBeenCalculatedOnce &&
            taxInformation?.items &&
            taxInformation?.items.length
              ? (taxInformation?.items
                  ?.map((a) => a.amount)
                  .reduce(
                    (previousValue, currentValue) =>
                      (previousValue || 0) + (currentValue || 0)
                  ) || 0) / 100
              : 0) * 100
          ) / 100,
      items: [
        {
          id: selectedPlan.productId,
          name: selectedPlan.name,
          quantity: 1,
          discount: selectedPlan.isFreeTrial
            ? 0
            : Math.round(
                (promoCode.promoCode
                  ? (selectedPlan.price - promoCode.priceAfterPromoCode) / 100
                  : 0) * 100
              ) / 100,
          price: selectedPlan.isFreeTrial
            ? 0
            : Math.round((selectedPlan.price / 100) * 100) / 100,
        },
      ],
    });
      //everflow
    try {
      console.log("Everflow transaction ID from cookie or local storage: " + window.EF.getAdvertiserTransactionId(4));

      const conversionParameters = {
        aid: 4,
        amount: selectedPlan.isFreeTrial
          ? 0
          : Math.round(
            ((promoCode.promoCodeApplied
              ? promoCode.priceAfterPromoCode
              : selectedPlan.price) /
              100 +
              (taxInformation?.total || 0) / 100) *
            100
          ) / 100,
        email: currentUser?.name ?? "",
        order_id: result.subscription.id,
        adv1: selectedPlan?.stripePricingId ?? "",
        adv2: result.subscription.items?.data[0]?.id,
        adv3: "50",
        adv4: "therapist.com",
        adv5: "therapist.com",
      };

      try {
        await apiWorker.post(
          `${basePathName}/api/billing/logconversion/attempted`,
          {
            ...conversionParameters,
            transaction_id: window.EF.getAdvertiserTransactionId(4)
          });
      } catch (e) {
        console.warn(e);
      }

      const { conversion_id, transaction_id } = await window.EF.conversion({
        ...conversionParameters
      });
      console.log("Conversion posted to Everflow", {
        ...conversionParameters,
        conversion_id,
        transaction_id
      });

      try {
        await apiWorker.post(
          `${basePathName}/api/billing/logconversion/sent`,
          {
            ...conversionParameters,
            conversion_id,
            transaction_id
          });
      } catch (e) {
        console.warn(e);
      }
    } catch (e) {
      console.error("Could not complete conversion", e);

      try {
        await apiWorker.post(`${basePathName}/api/billing/logconversion/failed`);
      } catch (e) {
        console.warn(e);
      }
    }

    if (result.subscription.status === "trialing") {
      return true;
    }

    if (result.subscription.status === "active") {
      let counter: number = 30;
      while (counter > 0) {
        counter--;
        const success = await apiWorker
          .get<boolean>(`${basePathName}/api/billing/checkpayment`)
          .catch(() => {});

        if (success && success.data) {
          toast.success(
            selectedPlan.isExistingAccount
              ? messageForExistingAccount
              : "Welcome! Your account has been created and you can start creating your public profile"
          );
          doRedirect = true;
          break;
        } else {
          await sleep(1000);
        }
      }

      if (counter === 0) {
        setDisplayTimeoutError(true);
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    }

    return doRedirect;
  };

  const sleep = (milliseconds: number) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };

  const {
    values,
    formErrors,
    touched,
    handleChange,
    handleBlurNoValidate,
    handleSubmit,
    handleChangeInDropDown,
    handleYesNoRadioChange,
    radioButtonChange,
    setValues,
    setFormErrors,
  } = useCustomForm({
    initialValues,
    validationSchema,
    onSubmit: async (values: any) => {
      setPreventBrowserBlockNavigation(true);
      setProcessing(true);

      if (skipBillingAddressValidation) {
        // no additinoal address validation needed
        setFormErrors({});
        await submitPayment();
        return;
      }

      if (Object.keys(values.errors || {}).length > 0) {
        // need to merge both errors so that both get displayed
        setFormErrors({ ...error, ...formErrors });
        setPreventBrowserBlockNavigation(false);
        setProcessing(false);
        return;
      }

      //if the validated address that comes back from the server differs from the one entered into the form,
      // display a verification modal to the user
      if (
        taxInformation?.address?.address1 !== values.values.address ||
        taxInformation?.address?.city !== values.values.city ||
        (!values.values.state &&
          values.values.province !== taxInformation?.address?.state?.label) ||
        (values.values.state &&
          values.values.state.label !==
            taxInformation?.address?.state?.label) ||
        taxInformation?.address?.zip !== values.values.zip
      ) {
        toggleModifiedAddressModal();
        let newValue: any = {};
        newValue.address = taxInformation?.address?.address1 || "";
        newValue.suite = "";
        newValue.city = taxInformation?.address?.city || "";
        newValue.country = values.values.country || new CoreEnum();
        if (
          values.values.country.value === US ||
          values.values.country.value === CA
        ) {
          newValue.state = taxInformation?.address?.state || new CoreEnum();
          newValue.province = "";
        } else {
          newValue.province = taxInformation?.address?.state?.value || "";
          newValue.state = new CoreEnum();
        }
        newValue.zip = taxInformation?.address?.zip || "";

        setValues({ ...newValue });
        setPreventBrowserBlockNavigation(false);
        setProcessing(false);
        return;
      }

      await submitPayment();
    },
  });

  const submitPayment = async () => {
    let doRedirect = false;

    if (!stripe || !elements) {
      setPreventBrowserBlockNavigation(false);
      setProcessing(false);
      return;
    }

    if (
      isNotUndefined(cardErrors.cardNumber) ||
      isNotUndefined(cardErrors.cardExpiry) ||
      isNotUndefined(cardErrors.cardCvc)
    ) {
      elements.getElement("card")?.focus();
      setPreventBrowserBlockNavigation(false);
      setProcessing(false);
      return;
    }

    let id = selectedPlan.productId;
    var el = elements.getElement("cardNumber");

    //no payment due yet - free trial or promo code 
    if (
      (selectedPlan.isFreeTrial && selectedPlan.isCreditCardRequired) ||
      (selectedPlan?.price < 0.01 && promoCode.promoCodeApplied)
    ) {
      // create customer & setup intent id
      const intentId = await apiWorker
        .post<string>(
          `${basePathName}/api/billing/createcustomerforsetupintentid`
        )
        .catch(() => {});
      let setupIntentIdToken = "";

      if (intentId && intentId.data) {
        setupIntentIdToken = intentId.data;
      } else {
        setPreventBrowserBlockNavigation(false);
        setProcessing(false);
        displayError(genericError);
        return;
      }

      const intentPayload =
        selectedExistingPaymentMethod && selectedExistingPaymentMethod.value
          ? await confirmCardSetupWithExistingPaymentMethod(
              selectedExistingPaymentMethod.value.toString(),
              setupIntentIdToken,
              stripe
            )
          : await confirmCardSetup(values, el!, setupIntentIdToken, stripe);

      if (intentPayload && intentPayload.error) {
        setPreventBrowserBlockNavigation(false);
        displayErrorPayload(intentPayload);
      } else {
        //set customer billing address
        await setBillingAddress();

        doRedirect = await createSubscription(
          intentPayload.setupIntent.payment_method || "",
          id,
          selectedPlan.campaignId,
          promoCode.promoCode ? promoCode.promoCode : "",
          selectedPlan.name
        );
      }

      setPreventBrowserBlockNavigation(false);
      setProcessing(false);
      if (doRedirect) {
        await authService.changeUserToRegistered();
        dispatch({
          type: GlobalActionTypes.SetSubscriptionStatus,
          payload: "registered",
        });
        toast.success(
          selectedPlan.isExistingAccount
            ? messageForExistingAccount
            : "Welcome! Your account has been created and you can start creating your public profile"
        );
        history.push("/provider-profile");
      }
    } else {
      //payment due now
      // create customer first
      let exceptionThrown = false;
      var customer = await apiWorker
        .post<CustomerVM>(
          `${basePathName}/api/billing/createstripecustomer`,
          {},
          {}
        )
        .catch((err) => {
          setPreventBrowserBlockNavigation(false);
          displayError(genericError);
          exceptionThrown = true;
        });

      if (
        !customer ||
        !customer.data ||
        !customer.data.customerId ||
        exceptionThrown
      ) {
        setPreventBrowserBlockNavigation(false);
        displayError(genericError);
        return;
      }

      if (
        selectedExistingPaymentMethod &&
        selectedExistingPaymentMethod.value
      ) {
        doRedirect = await createSubscription(
          selectedExistingPaymentMethod.value.toString(),
          id,
          selectedPlan.campaignId,
          promoCode.promoCode ? promoCode.promoCode : "",
          selectedPlan.name
        );
      } else {
        const paymentPayload = await stripe.createPaymentMethod({
          type: "card",
          card: el!,
          billing_details: {
            address: {
              city: values?.city || values?.values?.city,
              line1:
                (values?.address || values?.values?.address) +
                (values?.suite || values?.values?.suite
                  ? " " + (values?.suite || values?.values?.suite)
                  : ""),
              postal_code: values?.zip || values?.values?.zip,
              state: !isStateFreeform
                ? values?.state.label || values?.values?.state.label
                : values?.province || values?.values?.province,
              country: values?.country.value || values?.values?.country.value,
            },
          },
        });

        if (paymentPayload && paymentPayload.error) {
          displayError(
            paymentPayload.error.message
              ? paymentPayload.error.message
              : paymentPayload.error
          );
        } else {
          //set customer billing address
          await setBillingAddress();

          doRedirect = await createSubscription(
            paymentPayload.paymentMethod.id,
            id,
            selectedPlan.campaignId,
            promoCode.promoCode ? promoCode.promoCode : "",
            selectedPlan.name
          );
        }
      }

      setProcessing(false);
      setPreventBrowserBlockNavigation(false);
      if (doRedirect) {
        await authService.changeUserToRegistered();
        dispatch({
          type: GlobalActionTypes.SetSubscriptionStatus,
          payload: "registered",
        });
        history.push("/provider-profile");
      }
    }
  };

  const calculateTaxes = async (
    billingAddress: any,
    useExistingPaymentMethod: boolean = false
  ) => {
    // skip schema validation when using existing payment from Stripe
    if (!useExistingPaymentMethod) {
      let errors: any = {};
      try {
        await validationSchema.validate(billingAddress, {
          abortEarly: false,
          context: {},
        });
      } catch (e: any) {
        e.inner.map((err: Yup.ValidationError) => {
          errors[err.path!] = err.message;
        });
        setFormErrors(errors);
        return;
      }
      setFormErrors({});

      if (
        (isPostalCodeRequired && !billingAddress.zip) ||
        (isStateRequired &&
          !billingAddress.state?.label &&
          !billingAddress.province) ||
        !billingAddress.city ||
        !billingAddress.address ||
        !billingAddress.country?.label
      ) {
        return;
      }
    } else {
      setSkipBillingAddressValidation(true);
    }

    setIsCalculatingTaxes(true);

    if (cancel != undefined) {
      cancel();
    }

    var stateOrProvince = "";
    if (isStateFreeform) {
      stateOrProvince = billingAddress.province;
    } else {
      stateOrProvince = billingAddress.state.label;
    }

    var queryString = {
      ...billingAddress,
      state: stateOrProvince,
      country: billingAddress.country.value || US,
    };
    setStripeTaxAddressErrors({});

    //Save the cancel token for the current request
    const currentTaxInformation = await apiWorker
      .get<TaxVM>(
        `${basePathName}/api/billing/${
          useExistingPaymentMethod
            ? "calculatetaxesforpaymentmethod"
            : "calculatetaxes"
        }`,
        {
          params: {
            ...queryString,
          },
          headers: {
            "Content-Type": "application/json",
          },
          cancelToken: new CancelToken(function executor(c) {
            // An executor function receives a cancel function as a parameter
            cancel = c;
          }),
        }
      )
      .catch(() => {
        displayError(
          "There was an error calculating your taxes. Please verify the billing address."
        );
      });

    if (currentTaxInformation && currentTaxInformation.data) {
      setTaxInformation(currentTaxInformation.data);
      if (currentTaxInformation.data.success) {
        setTaxesHaveBeenCalculatedOnce(true);
        setError("");
      } else {
        setStripeTaxAddressErrors({
          zip: "",
          city: "",
          address: "",
          state: "",
          country: "",
          province: "",
          error: currentTaxInformation.data.errorMessage ?? "",
        });
      }
    }
    setIsCalculatingTaxes(false);
  };

  const submitPaymentWithModifiedAddress = async (event: any) => {
    setPreventBrowserBlockNavigation(true);
    setProcessing(true);
    toggleModifiedAddressModal();
    await submitPayment();
  };

  const displayErrorPayload = (payload: SetupIntentResult) => {
    setError(payload.error?.message ? payload.error.message : payload.error);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const displayError = (error: any) => {
    setError(error);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const translateStripeError = (error: string) => {
    switch (error) {
      case "Cannot connect to the payment provider's server. Please try again in a little bit.":
        return "We can't connect to our payment provider. Please try again.";
      case "Your card has been declined.":
        return "There was an issue processing your subscription with the credit card provided.  Please try again with a different credit card.";
      case "Your card has expired.":
        return "There was an issue processing your subscription with the credit card provided.  Please try again with a different credit card.";
      case "Please fill in your card details.":
        return "Please fill in your card details.";
      case "Your card's security code is incomplete.":
        return "Security code is incomplete.";
      case "Your card's expiration date is incomplete.":
        return "Expiration date is incomplete.";
      case "Your card number is incomplete.":
        return "Card number is incomplete.";
      case "Your card's security code is incorrect.":
        return "Security code is incorrect.";
      case "Your card number is incorrect.":
        return "Card number is incorrect.";
      case "Please enter the card holder's name.":
        return "Please enter the card holder's name.";
      case "Your card's security code is invalid.":
        return "Security code is invalid.";
      case "Your card's expiration date is invalid.":
        return "Expiration date is invalid.";
      case "Your card's expiration date is in the past.":
        return "Expiration date is in the past.";
      case "Your card's expiration year is invalid.":
        return "Expiration year is invalid.";
      case "Your card's expiration year is in the past.":
        return "Expiration year is in the past.";
      case "Your card number is invalid.":
        return "Card number is invalid.";
      case "An error occurred while processing your card. Please try again in a little bit.":
        return genericIssueError;
      case "An error occurred while processing your card.":
        return genericIssueError;
      case "Error":
        return genericError;
      case "An unexpected error occurred.":
        return genericError;
      default:
        return error;
    }
  };

  const confirmCardSetupWithExistingPaymentMethod = async (
    paymentMethodId: string,
    intentId: string,
    stripe: Stripe
  ): Promise<SetupIntentResult> => {
    return await stripe.confirmCardSetup(intentId, {
      payment_method: paymentMethodId,
    });
  };

  const confirmCardSetup = async (
    values: any,
    el: any,
    intentId: string,
    stripe: Stripe
  ): Promise<SetupIntentResult> => {
    return await stripe.confirmCardSetup(intentId, {
      payment_method: {
        card: el!,
        billing_details: {
          address: {
            city: values?.city || values?.values?.city,
            line1:
              (values?.address || values?.values?.address) +
              (values?.suite || values?.values?.suite
                ? " " + (values?.suite || values?.values?.suite)
                : ""),
            postal_code: values?.zip || values?.values?.zip,
            state: !isStateFreeform
              ? values?.state.label || values?.values?.state.label
              : values?.province || values?.values?.province,
            country: values?.country.value || values?.values?.country.value,
          },
        },
      },
    });
  };

  const applyPromoCode = async () => {
    toast.dismiss();
    const data = {
      promoCode: promoCodeString,
      productId: selectedPlan.productId,
    };
    let success = true;
    const promoCodeData = await apiWorker
      .get<PromoCodeVM>(`${basePathName}/api/billing/validatepromocode`, {
        params: {
          ...data,
        },
        headers: {
          "Content-Type": "application/json",
        },
      })
      .catch((err) => {
        displayError(err);
        setIsLoading(false);
      });

    if (!success) return;

    if (promoCodeData && promoCodeData.data && promoCodeData.data.isValid) {
      if (promoCode.promoCode && promoCode.promoCode !== promoCodeString) {
        console.log("previous promo code" + promoCode.promoCode);
        setPromoCodeNote(
          `Only one promo code valid per checkout. Your new code replaces previous promo code applied (${promoCode.promoCode})`
        );
      } else {
        setPromoCodeNote("");
      }

      promoCode.promoCode = promoCodeString;
      promoCode.promoCodeApplied = true;
      promoCode.priceAfterPromoCode = promoCodeData.data.priceAfterPromoCode;
      promoCode.promoCodeText = promoCodeData.data.text;

      setPromoCode(promoCode);
      setFormErrors({});
      setError("");
    } else {
      setFormErrors({
        [`promoCode`]: "The promo code you entered is not valid",
      });
    }
    setIsLoading(false);
  };

  const handlePromoCodeChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPromoCodeString(event.target.value);
  };

  return (selectedPlan.isFreeTrial && !selectedPlan.isCreditCardRequired) ||
    selectedPlan?.price < 0.01 ? (
    //free subscription no billing needed
    <Container fluid={true} className="billing">
      No billing needed!
    </Container>
  ) : (
    <Container fluid={true} className="billing">
      <Prompt
        when={preventBrowserNavigation}
        message={"Leave site? Changes you made may not be saved."}
      />
      <Modal isOpen={modifiedAddressModal} toggle={toggleModifiedAddressModal}>
        <ModalHeader toggle={toggleModifiedAddressModal}>
          Suggested Address
        </ModalHeader>
        <ModalBody>
          <div>
            The address you entered did not match a valid address but we found a
            close match and adjusted it for you:
          </div>
          <div>&nbsp;</div>
          <div>
            {taxInformation?.address?.address1 + (" " + values?.suite || "")}
          </div>
          <div>
            {taxInformation?.address?.city},{" "}
            {taxInformation?.address?.state?.label}{" "}
            {taxInformation?.address?.zip}
          </div>
          <div>{taxInformation?.address?.country?.label}</div>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={submitPaymentWithModifiedAddress}>
            Pay now
          </Button>
          <Button color="secondary" onClick={toggleModifiedAddressModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      <LoadingSection isLoading={isLoading} isFullPage={true}>
        <Form className="Form" onSubmit={handleSubmit}>
          <Row className="justify-content-center">
            <Col md="8">
              <Row>
                <Col md="12" className="pb-1 text-center">
                  <span className="large-title">Billing</span>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md="8">
              {displayTimeoutError && (
                <Alert color="warning">{timeoutError}</Alert>
              )}
              <ValidationErrors
                serverErrors={error ? [error] : []}
                formErrors={{}}
                customErrors={[]}
                className="large-margin"
              />
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md="4" className="light-blue pb-4">
              <Row>
                <Col md="12 py-3">
                  <div className="font-weight-bold large-font">
                    Billing Summary
                  </div>
                </Col>
              </Row>
              <Row className="pt-4 mx-4 pr-4">
                <Col className="font-weight-bold info">{selectedPlan.name}</Col>
              </Row>
              <Row className="pt-4 mx-4">
                <Col xs="8" className="info">
                  Subtotal
                </Col>
                <Col xs="4" className="info-value text-right pr-0">
                  $
                  {selectedPlan.isFreeTrial
                    ? "0.00"
                    : Helper.formatMoney(
                        (promoCode.promoCodeApplied
                          ? promoCode.priceAfterPromoCode
                          : selectedPlan.price) / 100,
                        2,
                        ".",
                        ","
                      )}
                </Col>
              </Row>
              {!taxesHaveBeenCalculatedOnce && !selectedPlan.isTaxExempt && (
                <Fragment>
                  <Row className="pt-4 mx-4 pr-4">
                    <Col xs="8" className="info">
                      Sales Tax
                    </Col>
                    <Col xs="4" className="info-value text-right pr-0"></Col>
                  </Row>
                </Fragment>
              )}
              {taxesHaveBeenCalculatedOnce &&
                taxInformation?.items?.map((item, index) => (
                  <Row className="pt-4 mx-4 pr-4" key={index}>
                    <Col md="8" className="info">
                      {item.displayName || "Sales Tax"} ({item.percentage}
                      {"%"})
                    </Col>
                    <Col md="4" className="info-value text-right pr-0">
                      {item.amount
                        ? `$${Helper.formatMoney(
                            item.amount / 100,
                            2,
                            ".",
                            ","
                          )}`
                        : "$0.00"}
                    </Col>
                  </Row>
                ))}
              <Row className="pt-1 mx-4 promo-row pr-4">
                {selectedPlan.displayPromoCodeEntry && (
                  <Fragment>
                    <Col md="8">
                      <TextInput
                        formGroupClass="white"
                        labelName="&nbsp;"
                        requiredField={false}
                        className={classnames({
                          highlighted: isNotUndefined(formErrors[`promoCode`]),
                        })}
                        fieldText={promoCodeString || ""}
                        fieldName="prices"
                        handleChange={(e) => {
                          handlePromoCodeChange(e);
                        }}
                        onBlur={() => {}}
                        isPassword={false}
                        autoComplete="off"
                        placeholder="Promo Code"
                        error={formErrors[`promoCode`]}
                      ></TextInput>
                    </Col>
                    <Col md="4">
                      <div className="d-none d-sm-block">&nbsp;</div>
                      <SkinnedButton
                        color="primary"
                        onClick={(event: any) => {
                          console.log("applying code");
                          if (!selectedPlan.isAdmin) applyPromoCode();
                        }}
                      >
                        Apply
                      </SkinnedButton>
                    </Col>
                    <Col md="12" className="pt-0 text-success">
                      {promoCode.promoCodeText}
                      {promoCodeNote && (
                        <div className="text-danger">{promoCodeNote}</div>
                      )}
                    </Col>
                  </Fragment>
                )}
              </Row>
              <Row className="pt-2 mx-2 dash-line">
                <Col></Col>
              </Row>
              <Row className="pt-2 mx-4">
                <Col xs="8" className="font-weight-bold info">
                  Total due today
                </Col>
                <Col
                  xs="4"
                  className="font-weight-bold info-value text-right pr-0"
                >
                  {selectedPlan.isFreeTrial
                    ? "$0.00"
                    : `$${Helper.formatMoney(
                        (promoCode.promoCodeApplied
                          ? promoCode.priceAfterPromoCode
                          : selectedPlan.price) /
                          100 +
                          (taxInformation?.total || 0) / 100,
                        2,
                        ".",
                        ","
                      )}`}
                </Col>
              </Row>
              {selectedPlan.isFreeTrial && (
                <Row className="pt-2 mx-4">
                  <Col xs="6" className="font-weight-bold info small-font">
                    Total due after trial
                  </Col>
                  <Col
                    xs="6"
                    className="font-weight-normal small-font info-value text-right pr-0"
                  >
                    {`$${Helper.formatMoney(
                      (promoCode.promoCodeApplied
                        ? promoCode.priceAfterPromoCode
                        : selectedPlan.price) / 100,
                      2,
                      ".",
                      ","
                    )}/${selectedPlan.billingFrequency} plus tax`}
                  </Col>
                </Row>
              )}
            </Col>
            <Col md="4" className="light-blue pb-4">
              <Row>
                <Col md="12 py-3">
                  <div className="font-weight-bold large-font">
                    Billing Information
                  </div>
                </Col>
                <Col md="12">
                  <div className="font-weight-bold small-font">
                    Card Information
                  </div>
                </Col>
                {selectedPlan &&
                  selectedPlan.paymentMethods &&
                  selectedPlan.paymentMethods.length > 0 && (
                    <>
                      <Col md="12">
                        <DataDropDown
                          fieldName="paymentMethod"
                          fieldText="Payment Method"
                          placeHolder="Select an existing payment method"
                          hideLabel={true}
                          formGroupClassName="mb-1"
                          requiredField={false}
                          styles={{
                            valueContainer: (base: any) => ({
                              ...base,
                              paddingLeft: "15px",
                            }),
                            placeholder: (base: any) => ({
                              ...base,
                              color: "#79829f",
                              fontWeight: 400,
                            }),
                            singleValue: (base: any) => ({
                              ...base,
                              color: "#79829f",
                            }),
                            control: (provided: any) => ({
                              ...provided,
                              border: blueBorder,
                              backgroundColor: white,
                            }),
                            dropdownIndicator: (provided: any) => ({
                              ...provided,
                              color: darkBlue,
                            }),
                          }}
                          values={selectedPlan.paymentMethods}
                          selectedValue={selectedExistingPaymentMethod}
                          handleChange={async (selected, text) => {
                            handleChangeInDropDown(selected, text);
                            var option = new CoreEnum(
                              selected.target.value,
                              text
                            );
                            setSelectedExistingPaymentMethod(option);
                            if (!elements) return;
                            if (!!selected.target.value) {
                              elements.getElement("cardNumber")?.clear();
                              elements.getElement("cardExpiry")?.clear();
                              elements.getElement("cardCvc")?.clear();
                              const address =
                                selectedPlan.billingAddresses.find(
                                  (x: BillingAddressVM) =>
                                    x.paymentMethodId === selected.target.value
                                );
                              const country = selectedPlan.countries.find(
                                (x: any) => x.isoCode === address.country
                              );
                              setIsStateFreeform(
                                country.isoCode !== US && country.isoCode !== CA
                              );
                              setValues({
                                ...values,
                                address: address.street,
                                city: address.city,
                                suite: "",
                                zip: address.zip,
                                state: isStateFreeform
                                  ? null
                                  : new CoreEnum(address.state, address.state),
                                country: new CoreEnum(
                                  address.country,
                                  country.label
                                ),
                                province: isStateFreeform ? address.state : "",
                              });
                              await calculateTaxes(
                                {
                                  productId: selectedPlan.productId,
                                  promoCode: promoCode.promoCode,
                                  address: address.street,
                                  suite: values.suite,
                                  city: address.city,
                                  state: isStateFreeform
                                    ? null
                                    : new CoreEnum(
                                        address.state,
                                        address.state
                                      ),
                                  province: isStateFreeform
                                    ? address.state
                                    : "",
                                  zip: address.zip,
                                  country: new CoreEnum(
                                    address.country,
                                    country.label
                                  ),
                                  isStateFreeform: isStateFreeform,
                                  isPostalCodeRequired:
                                    country.isPostalCodeRequired,
                                  isStateRequired: country.isStateRequired,
                                },
                                true
                              );
                            } else {
                              setValues({
                                ...values,
                                address: "",
                                city: "",
                                suite: "",
                                zip: "",
                                state: null,
                                country: "",
                                province: "",
                              });
                              setTaxesHaveBeenCalculatedOnce(false);
                            }
                            elements
                              .getElement("cardNumber")
                              ?.update({ disabled: !!selected.target.value });
                            elements
                              .getElement("cardExpiry")
                              ?.update({ disabled: !!selected.target.value });
                            elements
                              .getElement("cardCvc")
                              ?.update({ disabled: !!selected.target.value });
                          }}
                          className={classnames({
                            selectControl: true,
                          })}
                        ></DataDropDown>
                      </Col>
                      <Col md="12 pt-2">
                        <div className="font-weight-bold small-font">
                          New Card
                        </div>
                      </Col>
                    </>
                  )}
                <Col md="12">
                  <div
                    className={classnames({
                      highlighted: isNotUndefined(cardErrors.cardNumber),
                      "form-group": true,
                      "stripe-input": true,
                      disabled: selectedExistingPaymentMethod?.value,
                    })}
                  >
                    <FontAwesomeIcon
                      color="#5388eb"
                      icon={creditCardIcon}
                    ></FontAwesomeIcon>
                    <CardNumberElement
                      id="card_number"
                      options={CARD_OPTIONS}
                      onChange={(e: any) => {
                        setCardErrors({
                          ...cardErrors,
                          cardNumber:
                            e.error && e.error.message
                              ? e.error.message
                              : e.error,
                        });
                        if (e.brand) {
                          setBrandIcon(e.brand);
                        }
                      }}
                    ></CardNumberElement>
                  </div>
                  <ErrorMessage
                    visible={isNotUndefined(cardErrors.cardNumber)}
                    message={translateStripeError(cardErrors.cardNumber)}
                    className="pb-2 mt-n3"
                  />
                </Col>
                <Col md="6">
                  <div
                    className={classnames({
                      highlighted: isNotUndefined(cardErrors.cardExpiry),
                      "form-group": true,
                      "stripe-input": true,
                      disabled: selectedExistingPaymentMethod?.value,
                    })}
                  >
                    <CardExpiryElement
                      id="card_expiry"
                      options={CARD_EXPIRY_OPTIONS}
                      onChange={(e: any) => {
                        setCardErrors({
                          ...cardErrors,
                          cardExpiry:
                            e.error && e.error.message
                              ? e.error.message
                              : e.error,
                        });
                      }}
                    ></CardExpiryElement>
                  </div>
                  <ErrorMessage
                    visible={isNotUndefined(cardErrors.cardExpiry)}
                    message={translateStripeError(cardErrors.cardExpiry)}
                    className="pb-2 mt-n3"
                  />
                </Col>
                <Col md="6">
                  <div
                    className={classnames({
                      highlighted: isNotUndefined(cardErrors.cardCvc),
                      "form-group": true,
                      "stripe-input": true,
                      disabled: selectedExistingPaymentMethod?.value,
                    })}
                  >
                    <CardCvcElement
                      id="card_cvc"
                      options={CARD_EXPIRY_OPTIONS}
                      onChange={(e: any) => {
                        setCardErrors({
                          ...cardErrors,
                          cardCvc:
                            e.error && e.error.message
                              ? e.error.message
                              : e.error,
                        });
                      }}
                    ></CardCvcElement>
                  </div>
                  <ErrorMessage
                    visible={isNotUndefined(cardErrors.cardCvc)}
                    message={translateStripeError(cardErrors.cardCvc)}
                    className="pb-2 mt-n3"
                  />
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <div className="font-weight-bold small-font">
                    Billing Address
                  </div>
                </Col>
                <Col md="8">
                  <DataDropDown
                    fieldName="country"
                    fieldText="Country"
                    placeHolder="Country"
                    hideLabel={true}
                    requiredField={true}
                    styles={{
                      valueContainer: (base: any) => ({
                        ...base,
                        paddingLeft: "15px",
                      }),
                      placeholder: (base: any) => ({
                        ...base,
                        color: "#212940",
                        fontWeight: 300,
                      }),
                      singleValue: (base: any) => ({
                        ...base,
                        color: "#79829f",
                      }),
                      control: (provided: any) => ({
                        ...provided,
                        border:
                          isNotUndefined(formErrors["country.label"]) ||
                          isNotUndefined(formErrors["country"]) ||
                          isNotUndefined(formErrors["Country"]) ||
                          isNotUndefined(stripeTaxAddressErrors.country)
                            ? redBorder
                            : blueBorder,
                        backgroundColor:
                          isNotUndefined(formErrors["country.label"]) ||
                          isNotUndefined(formErrors["country"]) ||
                          isNotUndefined(formErrors["Country"]) ||
                          isNotUndefined(stripeTaxAddressErrors.country)
                            ? red
                            : !!selectedExistingPaymentMethod?.value
                            ? gray
                            : white,
                      }),
                      dropdownIndicator: (provided: any) => ({
                        ...provided,
                        color: darkBlue,
                      }),
                    }}
                    values={countriesCoreEnum}
                    // pre-select the value "United States" in the Country dropdown
                    selectedValue={
                      values.country ||
                      countriesCoreEnum.find((x: CoreEnum) => x.value === US)
                    }
                    handleChange={async (selected, text) => {
                      handleChangeInDropDown(selected, text);
                      //update states to those available for selected country
                      if (
                        states.find((x: any) => x[0] === selected.target.value)
                      ) {
                        setIsStateFreeform(false);
                        setVisibleStates(
                          states.find(
                            (x: any) => x[0] === selected.target.value
                          )![1]
                        );
                      } else {
                        setIsStateFreeform(true);
                        setVisibleStates([]);
                      }
                      //clear selected state when country changes
                      setValues({
                        ...values,
                        country: new CoreEnum(selected.target.value, text),
                        state: null,
                        province: "",
                      });
                      let selectedCountry =
                        countries.find(
                          (x) => x.isoCode === selected.target.value
                        ) ?? null;
                      if (selectedCountry) {
                        setIsPostalCodeRequired(
                          selectedCountry.isPostalCodeRequired ?? false
                        );
                        setIsStateRequired(
                          selectedCountry.isStateRequired ?? false
                        );
                      }
                    }}
                    className={classnames({
                      highlighted:
                        isNotUndefined(formErrors.country) ||
                        isNotUndefined(formErrors["country.label"]) ||
                        isNotUndefined(formErrors["Country"]) ||
                        isNotUndefined(stripeTaxAddressErrors.country),
                      selectControl: true,
                    })}
                    autoComplete="country"
                    error={
                      formErrors["country.label"] ||
                      formErrors["country"] ||
                      formErrors["Country"]
                    }
                    disabled={!!selectedExistingPaymentMethod?.value}
                  ></DataDropDown>
                </Col>
                <Col md="8">
                  <Field
                    maxLength={255}
                    id="address"
                    type="text"
                    placeholder="Address"
                    className={classnames({
                      highlighted:
                        isNotUndefined(formErrors.address) ||
                        isNotUndefined(stripeTaxAddressErrors.address),
                      "form-control": true,
                    })}
                    name="address"
                    onBlur={handleBlurNoValidate}
                    autoComplete="street-address"
                    value={values.address || ""}
                    onChange={async (event: any) => {
                      handleChange(event);
                      await calculateTaxes({
                        productId: selectedPlan.productId,
                        promoCode: promoCode.promoCode,
                        address: event.target.value,
                        suite: values.suite,
                        city: values.city,
                        state: values.state,
                        zip: values.zip,
                        country: values.country,
                        province: values.province,
                        isStateFreeform: isStateFreeform,
                        isPostalCodeRequired: isPostalCodeRequired,
                        isStateRequired: isStateRequired,
                      });
                    }}
                    error={formErrors.address}
                    disabled={!!selectedExistingPaymentMethod?.value}
                  />
                </Col>
                <Col md="4">
                  <Field
                    maxLength={10}
                    id="suite"
                    type="text"
                    placeholder="Suite"
                    className={classnames({
                      highlighted: isNotUndefined(formErrors.suite),
                      "form-control": true,
                    })}
                    name="suite"
                    onBlur={handleBlurNoValidate}
                    autoComplete="suite"
                    value={values.suite || ""}
                    onChange={async (event: any) => {
                      handleChange(event);
                      await calculateTaxes({
                        productId: selectedPlan.productId,
                        promoCode: promoCode.promoCode,
                        address: values.address,
                        suite: event.target.value,
                        city: values.city,
                        state: values.state,
                        zip: values.zip,
                        country: values.country,
                        province: values.province,
                        isStateFreeform: isStateFreeform,
                        isPostalCodeRequired: isPostalCodeRequired,
                        isStateRequired: isStateRequired,
                      });
                    }}
                    error={formErrors.suite}
                    disabled={!!selectedExistingPaymentMethod?.value}
                  />
                </Col>
                <Col md="12">
                  <Field
                    maxLength={100}
                    id="city"
                    type="text"
                    placeholder="City"
                    name="city"
                    className={classnames({
                      highlighted:
                        isNotUndefined(formErrors.city) ||
                        isNotUndefined(stripeTaxAddressErrors.city),
                      "form-control": true,
                    })}
                    autoComplete="city"
                    value={values.city || ""}
                    onChange={async (event: any) => {
                      handleChange(event);
                      await calculateTaxes({
                        productId: selectedPlan.productId,
                        promoCode: selectedPlan.promoCode,
                        address: values.address,
                        suite: values.suite,
                        city: event.target.value,
                        state: values.state,
                        zip: values.zip,
                        country: values.country,
                        province: values.province,
                        isStateFreeform: isStateFreeform,
                        isPostalCodeRequired: isPostalCodeRequired,
                        isStateRequired: isStateRequired,
                      });
                    }}
                    onBlur={handleBlurNoValidate}
                    error={formErrors.city}
                    disabled={!!selectedExistingPaymentMethod?.value}
                  />
                </Col>
                <Col md="8">
                  {visibleStates && visibleStates.length > 0 && (
                    <DataDropDown
                      fieldName="state"
                      fieldText="State"
                      placeHolder={
                        values?.country?.value === US
                          ? "State"
                          : "Province/Region"
                      }
                      hideLabel={true}
                      requiredField={true}
                      styles={{
                        valueContainer: (base: any) => ({
                          ...base,
                          paddingLeft: "15px",
                        }),
                        placeholder: (base: any) => ({
                          ...base,
                          color: "#212940",
                          fontWeight: 300,
                        }),
                        singleValue: (base: any) => ({
                          ...base,
                          color: "#79829f",
                        }),
                        control: (provided: any) => ({
                          ...provided,
                          border:
                            isNotUndefined(formErrors["state.label"]) ||
                            isNotUndefined(formErrors["state"]) ||
                            isNotUndefined(formErrors["State"]) ||
                            isNotUndefined(stripeTaxAddressErrors.state)
                              ? redBorder
                              : blueBorder,
                          backgroundColor:
                            isNotUndefined(formErrors["state.label"]) ||
                            isNotUndefined(formErrors["state"]) ||
                            isNotUndefined(formErrors["State"]) ||
                            isNotUndefined(stripeTaxAddressErrors.state)
                              ? red
                              : !!selectedExistingPaymentMethod?.value
                              ? gray
                              : white,
                        }),
                        dropdownIndicator: (provided: any) => ({
                          ...provided,
                          color: darkBlue,
                        }),
                      }}
                      values={visibleStates}
                      selectedValue={values.state}
                      handleChange={async (selected, text) => {
                        handleChangeInDropDown(selected, text);
                        await calculateTaxes({
                          productId: selectedPlan.productId,
                          promoCode: promoCode.promoCode,
                          address: values.address,
                          suite: values.suite,
                          city: values.city,
                          state: {
                            value: selected.target.value,
                            label: text,
                          },
                          zip: values.zip,
                          country: values.country,
                          province: "",
                          isStateFreeform: false,
                          isPostalCodeRequired: isPostalCodeRequired,
                          isStateRequired: isStateRequired,
                        });
                      }}
                      className={classnames({
                        highlighted:
                          isNotUndefined(formErrors.state) ||
                          isNotUndefined(formErrors["state.label"]) ||
                          isNotUndefined(formErrors["State"]) ||
                          isNotUndefined(stripeTaxAddressErrors.state),
                        selectControl: true,
                      })}
                      autoComplete="address-level1"
                      error={
                        formErrors["state.label"] ||
                        formErrors["state"] ||
                        formErrors["State"]
                      }
                      disabled={!!selectedExistingPaymentMethod?.value}
                    ></DataDropDown>
                  )}
                  {(!visibleStates || visibleStates.length === 0) && (
                    <Field
                      maxLength={100}
                      id="province"
                      type="text"
                      placeholder="Province/Region"
                      name="province"
                      className={classnames({
                        highlighted:
                          isNotUndefined(formErrors.province) ||
                          isNotUndefined(stripeTaxAddressErrors.province),
                        "form-control": true,
                      })}
                      autoComplete="address-level1"
                      value={values.province || ""}
                      onChange={async (event: any) => {
                        handleChange(event);
                        await calculateTaxes({
                          productId: selectedPlan.productId,
                          promoCode: selectedPlan.promoCode,
                          address: values.address,
                          suite: values.suite,
                          city: values.city,
                          province: event.target.value,
                          state: {
                            value: "",
                            label: "",
                          },
                          zip: values.zip,
                          country: values.country,
                          isStateFreeform: true,
                          isPostalCodeRequired: isPostalCodeRequired,
                          isStateRequired: isStateRequired,
                        });
                      }}
                      error={formErrors.province}
                      onBlur={handleBlurNoValidate}
                      disabled={!!selectedExistingPaymentMethod?.value}
                    />
                  )}
                </Col>
                <Col>
                  <Field
                    maxLength={12}
                    id="zip"
                    type="text"
                    placeholder={
                      values?.country?.value === US ? "Zip" : "Postal Code"
                    }
                    name="zip"
                    autoComplete="postal-code"
                    className={classnames({
                      highlighted:
                        isNotUndefined(formErrors.zip) ||
                        isNotUndefined(stripeTaxAddressErrors.zip),
                      "form-control": true,
                    })}
                    value={values.zip || ""}
                    onChange={async (event: any) => {
                      handleChange(event);
                      await calculateTaxes({
                        productId: selectedPlan.productId,
                        promoCode: promoCode.promoCode,
                        address: values.address,
                        suite: values.suite,
                        city: values.city,
                        state: values.state,
                        zip: event.target.value,
                        country: values.country,
                        province: values.province,
                        isStateFreeform: isStateFreeform,
                        isPostalCodeRequired: isPostalCodeRequired,
                        isStateRequired: isStateRequired,
                      });
                    }}
                    onBlur={handleBlurNoValidate}
                    error={formErrors.zip}
                    disabled={!!selectedExistingPaymentMethod?.value}
                  />
                </Col>
              </Row>
              <ErrorMessage
                visible={isNotUndefined(stripeTaxAddressErrors.error)}
                message={stripeTaxAddressErrors.error}
              />
            </Col>
          </Row>
          {selectedPlan.isFreeTrial && (
            <Row className="justify-content-center">
              <Col md="9" className="mt-2 pl-4 ml-4">
                After your trial ends, you will be charged $
                {Helper.formatMoney(
                  (promoCode.promoCodeApplied
                    ? promoCode.priceAfterPromoCode
                    : selectedPlan.price) / 100,
                  2,
                  ".",
                  ","
                )}{" "}
                per {selectedPlan.billingFrequency} plus tax starting{" "}
                {format(
                  addDays(new Date(), selectedPlan.freeTrialDays),
                  "MMMM dd, yyyy"
                )}
                . You can always cancel before then.
              </Col>
            </Row>
          )}
          <Row className="justify-content-center pt-3">
            <Col md="3" className="pb-4">
              <SkinnedButton
                color="primary"
                className={`full-width SubmitButton ${
                  error ? "SubmitButton--error" : ""
                }`}
                type="submit"
                disabled={
                  !taxesHaveBeenCalculatedOnce ||
                  isCalculatingTaxes ||
                  !taxInformation?.success ||
                  processing ||
                  !stripe ||
                  isNotUndefined(cardErrors.cardNumber) ||
                  isNotUndefined(cardErrors.cardExpiry) ||
                  isNotUndefined(cardErrors.cardCvc)
                }
              >
                <div
                  className={classnames({
                    "d-none": !isCalculatingTaxes && !processing,
                    "d-inline-block": isCalculatingTaxes || processing,
                    "mr-2": isCalculatingTaxes || processing,
                    "checkout-spinner": true,
                  })}
                  id="checkout-spinner"
                ></div>
                <span id="button-text">
                  {isCalculatingTaxes
                    ? "Calculating taxes..."
                    : processing
                    ? "Redirecting to your profile"
                    : "Pay"}
                </span>
              </SkinnedButton>
              <SkinnedButton
                onClick={() => {
                  let userSubscriptionPath = "/usersubscription";
                  if (campaignPath && campaignPath.length > 0) {
                    userSubscriptionPath = `${userSubscriptionPath}/${campaignPath}/`;
                  }
                  history.push(userSubscriptionPath);
                }}
                className="mt-4 full-width"
                disabled={processing}
              >
                Go back
              </SkinnedButton>
            </Col>
          </Row>
        </Form>
      </LoadingSection>
    </Container>
  );
};
