import { Fragment, useState, useEffect } from "react";
import ReactPixel from "react-facebook-pixel";
import {
  Card,
  CardTitle,
  Col,
  Container,
  CustomInput,
  Form,
  Label,
  Row,
} from "reactstrap";
import "./Subscription.scss";
import { useApiWorker } from "../../Utilities/CommonHooks";
import { CampaignResponseVM, BillingAddressVM, CountryVM } from "./Models/index";
import {
  useGlobalDispatch,
  GlobalActionTypes,
  useGlobalState,
} from "../../Context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Icon from "@fortawesome/free-solid-svg-icons";
import { LoadingSection } from "../../Components/Display";
import ValidationErrors from "../Errors/ValidationErrors";
import SessionExpiredError from "../Errors/SessionExpiredError";
import { SkinnedButton } from "../../Components/Form/Buttons";
import {
  basePathName,
  genericServerError,
  Helper,
} from "../../Utilities/HelperData";
import classNames from "classnames";
import { ProductDetailVM, ProductFeatureVM } from "../Product/Models";
import { CoreEnum } from "../Shared/Models/SharedModels";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import authService from "../../Components/ApiAuthorization/AuthorizeService";
import { SubscriptionStatus } from "../../Components/ProviderProfile/Models";
import { SessionStorageHelper } from "../../Utilities/SessionStorageHelper";
import { constants } from "../../Utilities/Constants";

export const SubscriptionSelect = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const {
    currentUser,
    customerSupportContactPhoneNumber,
    customerSupportContactEmail,
  } = useGlobalState();
  const [serverErrors, setServerErrors] = useState<any>(null);
  const [sessionExpired, setSessionExpired] = useState<boolean>(false);
  const apiWorker = useApiWorker();
  const [values, setValues] = useState<ProductDetailVM[]>([]);
  const [states, setStates] = useState<CoreEnum[]>([]);
  const [countries, setCountries] = useState<CountryVM[]>([]);
  const [isTaxExempt, setIsTaxExempt] = useState(false);
  const [campaignId, setCampaignId] = useState("");
  const [paymentMethods, setPaymentMethods] = useState<CoreEnum[]>([]);
  const [isExistingAccount, setIsExistingAccount] = useState<boolean>();
  const [isForwardToSignUp, setIsForwardToSignUp] = useState<boolean>();
  const [billingAddresses, setBillingAddresses] = useState<BillingAddressVM[]>(
    []
  );
  const dispatch = useGlobalDispatch();
  const history = useHistory();
  const [processing, setProcessing] = useState(false);
  const genericError = `There was an unexpected error with your request, please try again. If further issues persist please contact customer service at ${customerSupportContactEmail} or ${customerSupportContactPhoneNumber}.`;
  const displayError = (error: any) => {
    setServerErrors(error);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const { offerPage } = useParams<{ offerPage: string }>();
  const passedDataString =
      window.sessionStorage.getItem(constants.SelectedSubscriptionSession) ?? "";
  const selectedPlan =
      passedDataString !== "" ? JSON.parse(passedDataString) : null;

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setServerErrors(null);
    if (!values?.filter((x: any) => x.isSelected).length) {
      setServerErrors(["Please select a subscription"]);
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      return;
    }
    setIsLoading(true);

    const selectedPlan = (values || []).find((x: any) => x!.isSelected);
    if (selectedPlan) {
      let subscription = {
        campaignId: campaignId,
        productId: selectedPlan?.id,
        name: selectedPlan?.name,
        displayPromoCodeEntry: selectedPlan?.displayPromoCodeEntry,
        price: selectedPlan?.price,
        isFreeTrial: selectedPlan?.isFreeTrial,
        billingFrequency: selectedPlan?.billingFrequency,
        freeTrialDays: selectedPlan?.freeTrialDays,
        states: states,
        countries: countries,
        isTaxExempt: isTaxExempt,
        isCreditCardRequired: selectedPlan?.isCreditCardRequired,
        paymentMethods: paymentMethods,
        isExistingAccount: isExistingAccount,
        billingAddresses: billingAddresses,
        stripeProductId: selectedPlan?.stripeProductId,
        stripePricingId: selectedPlan?.stripePricingId,
      };
      window.sessionStorage.setItem(
        constants.SelectedSubscriptionSession,
        JSON.stringify(subscription)
      );
      if (currentUser) {
        //create a free subscription if the user exists - may want to try to move this code.
        if (
          (selectedPlan.isFreeTrial && !selectedPlan.isCreditCardRequired) ||
          selectedPlan.price < 0.01
        ) {
          let exceptionThrown = false;
          setProcessing(true);
            const result = await apiWorker
            .post<any>(
              `${basePathName}/api/billing/createfreesubscription`,
              {
                campaignId: campaignId,
                productId: selectedPlan.id,
                promoCode: null,
                productName: selectedPlan.name,
              },
              {}
            )
            .catch((err) => {
              displayError(genericError);
              setIsLoading(false);
              exceptionThrown = true;
            });

          setProcessing(false);
          if (exceptionThrown) {
            return false;
          }

          if (result && result.data) {
            ReactPixel.track("Purchase", {
              currency: "USD",
              value: 0,
              content_name: selectedPlan.name,
              contents: [
                {
                  id: selectedPlan.id,
                  quantity: 1,
                },
              ],
            });
            window.gtag("event", "purchase", {
              non_interaction: true,
              transaction_id: result.data.id,
              value: 0,
              currency: "USD",
              tax: 0,
              items: [
                {
                  id: selectedPlan.id,
                  name: selectedPlan.name,
                  quantity: 1,
                  price: 0,
                },
              ],
            });
            //everflow
            try {
              console.log("Everflow transaction ID from cookie or local storage: " + window.EF.getAdvertiserTransactionId(4));

              const conversionParameters = {
                aid: 4,
                amount: 0,
                email: currentUser?.name ?? "",
                order_id: result.data?.id ?? "",
                adv1: selectedPlan.stripePricingId ?? "",
                adv2: result.data.items?.data[0]?.id ?? "",
                adv3: "50",
                adv4: "therapist.com",
                adv5: "therapist.com",
              };

              try {
                await apiWorker.post(
                  `${basePathName}/api/billing/logconversion/attempted`,
                  {
                    ...conversionParameters,
                    transaction_id: window.EF.getAdvertiserTransactionId(4)
                  });
              } catch (e) {
                console.warn(e);
              }

              const { conversion_id, transaction_id } = await window.EF.conversion({
                ...conversionParameters
              });

              console.log("Conversion posted to Everflow", {
                ...conversionParameters,
                conversion_id,
                transaction_id
              });

              try {
                await apiWorker.post(
                  `${basePathName}/api/billing/logconversion/sent`,
                  {
                    ...conversionParameters,
                    conversion_id,
                    transaction_id
                  });
              } catch (e) {
                console.warn(e);
              }
            } catch (e) {
              console.error("Could not complete conversion", e);

              try {
                await apiWorker.post(`${basePathName}/api/billing/logconversion/failed`);
              } catch (e) {
                console.warn(e);
              }
            }
            toast.success(
              "Welcome! Your account has been created and you can start creating your public profile"
            );

            await authService.changeUserToRegistered();
            dispatch({
              type: GlobalActionTypes.SetSubscriptionStatus,
              payload: "registered",
            });
            //subscription created, no billing needed - go to provider-profile
            history.push("/provider-profile");
          } else {
            displayError(genericError);
            setIsLoading(false);
          }
        } else {
          //not a free subscription, go to billing to finish
          history.push("/billing");
        }
      } else {
        //user not signed in - either needs to sign in or create an account
        if (isForwardToSignUp) {
          window.location.replace(
            "/providers/account/register?subscriptionSelected=true"
          );
        } else {
          window.location.replace(
            "/providers/account/login?subscriptionSelected=true"
          );
        }
      }
    }
  };
  useEffect(() => {
    getCampaign();
    SessionStorageHelper.clearSessionStorageItem(constants.ReturnUrl);
  }, []);

  useEffect(() => {
    Helper.setMinHeight();
  }, []);

  useEffect(() => {
    setIsAdmin(
      (currentUser?.permissions || []).some((x: any) =>
        ["providerprofile.editany"].includes(x)
      )
    );
  }, [currentUser]);

  const handleServerErrors = (errors: any): boolean => {
    if (errors?.status === 401) {
      setSessionExpired(true);
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      return true;
    } else if (errors?.status === 500) {
      setServerErrors([
        genericServerError +
          `${customerSupportContactEmail} or ${customerSupportContactPhoneNumber}.`,
      ]);
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      return true;
    }
    return false;
  };

  const radioButtonChanged = (event: any, targetItem: number) => {
    event.preventDefault();
    for (let i = 0; i < values.length; i++) {
      if (i !== targetItem) {
        values[i].isSelected = false;
      }
    }
    values[targetItem].isSelected = true;
    setValues([...values]);
  };

  const getCampaign = async () => {
    // get product id which is important in case the promo code only applies
    // to certain products
    let success = true;
    setIsLoading(true);
    let controllerAction = currentUser ? "billing" : "publiccampaign";
    const campaign = await apiWorker
      .get<CampaignResponseVM>(
        `${basePathName}/api/${controllerAction}/getcampaign${offerPage ? `/${offerPage}` : ``}`
      )
      .catch((err) => {
        handleServerErrors(err);
        setIsLoading(false);
      });

    if (!success) {
      setIsLoading(false);
      return;
    }

    if (
      campaign &&
      campaign.data &&
      campaign.data.subscriptionStatus &&
      campaign.data.subscriptionStatus.value == SubscriptionStatus.Active
    ) {
      await authService.changeUserToRegistered();
      dispatch({
        type: GlobalActionTypes.SetSubscriptionStatus,
        payload: "registered",
      });

    } else if (campaign && campaign.data && campaign.data.customMessage) {
      setServerErrors([campaign.data.customMessage]);
    }

    if (
      selectedPlan &&
      selectedPlan.productId &&
      campaign &&
      campaign.data &&
      campaign.data.products
    ) {
      if (
        campaign.data.products.length >= 1 &&
        campaign.data.products[0].id == selectedPlan.productId
      ) {
        campaign.data.products[0].isSelected = true;
      } else if (
        campaign.data.products.length >= 2 &&
        campaign.data.products[1].id == selectedPlan.productId
      ) {
        campaign.data.products[1].isSelected = true;
      } else if (
        campaign.data.products.length >= 3 &&
        campaign.data.products[2].id == selectedPlan.productId
      ) {
        campaign.data.products[2].isSelected = true;
      }
    } else if (campaign && campaign.data && campaign.data.products) {
      for (let i = 0; i < campaign.data.products?.length; i++) {
        if (campaign.data.products[i].isDefaultSelection) {
          campaign.data.products[i].isSelected = true;
          break;
        }
      }
    }

    if (campaign && campaign.data) {  
      window.sessionStorage.setItem(
        constants.CampaignSession,
        offerPage
      );
      setValues([...(campaign.data.products || [])]);
      setStates(campaign.data.states || []);
      setCountries(campaign.data.countries || []);
      setIsTaxExempt(campaign.data.isTaxExempt || false);
      setCampaignId(campaign.data.campaignId || "");
      setBillingAddresses(campaign.data.billingAddresses || []);
      if (campaign.data.paymentMethods && campaign.data.paymentMethods.length) {
        campaign.data.paymentMethods.splice(0, 0, new CoreEnum("", "New card"));
      }
      setIsExistingAccount(campaign.data.isExistingAccount || false);
      setPaymentMethods(campaign.data.paymentMethods || []);
      setIsForwardToSignUp(campaign.data.isForwardToSignUp || false);
    }
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setIsLoading(false);
  };

  return (
    <Container fluid={true}>
      <LoadingSection isLoading={isLoading} isFullPage={true}>
        <Form className="mt-4" onSubmit={handleSubmit}>
          <Row className="justify-content-center">
            <Col
              md="12"
              className={classNames({
                // Max of 3 subscriptions
                "col-lg-12": values?.length === 3,
                "col-lg-9": values?.length !== 3,
              })}
            >
              {/* Hide this alert for now (See #4140) */}
              {/* {displayProviderInformation && (
                <Row>
                  <Col md={12}>
                    <Alert color="warning" className="text-center medium-font">
                      <FontAwesomeIcon
                        className="mr-3"
                        icon={Icon.faInfoCircle}
                        size="lg"
                      ></FontAwesomeIcon>
                      A subscription already exists under the email{" "}
                      {currentUser?.preferred_username}. If you'd like to
                      continue with the existing subscription
                      <u>
                        &nbsp;
                        <Link
                          className="font-weight-normal"
                          to="/provider-profile"
                        >
                          click here to log in to your account
                        </Link>
                      </u>
                      . If you'd like to change your subscription, proceed with
                      selecting a subscription below.
                    </Alert>
                  </Col>
                </Row>
              )} */}
              <Row>
                <Col md="12">
                  <ValidationErrors
                    serverErrors={serverErrors ? [serverErrors] : []}
                    formErrors={[]}
                    customErrors={[]}
                  />
                  <SessionExpiredError showError={sessionExpired} />
                </Col>
                <Col 
                  className={classNames({
                    "pb-1": true, 
                    "col-md-12": values.length !== 1,
                    "col-md-6": values.length === 1,
                    "offset-md-3": values.length === 1
                  })}
                >
                  <span className="large-title">Subscription</span>
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <Row>
                    <Col
                      className={classNames({
                        "col-md-12": values.length !== 1,
                        "col-md-6": values.length === 1,
                        "offset-md-3": values.length === 1
                      })}
                    >
                      <Label className="header2">Choose a plan:</Label>
                    </Col>
                  </Row>
                  <Row className="plans">
                    {values &&
                      values.length > 0 &&
                      values?.map((item: ProductDetailVM, index: number) => (
                        <Fragment key={item.id}>
                          <div
                            className={classNames({
                              // Max of 3 subscriptions
                              "col-md-4": values?.length === 3,
                              "col-md-6": values?.length !== 3,
                              "offset-md-3": values?.length === 1
                            })}
                          >
                            <Card
                              onClick={(event: any) => {
                                radioButtonChanged(event, index);
                              }}
                              body
                              className={classNames({
                                isSelected: item.isSelected === true,
                              })}
                            >
                              <CardTitle
                                className={classNames({
                                  "mb-4": true,
                                  popular: !!item.tag,
                                })}
                              >
                                <CustomInput
                                  type="radio"
                                  id={`planType${index}`}
                                  name="planType"
                                  label={item.name}
                                  checked={item.isSelected || false}
                                  onChange={(event: any) => {}}
                                />
                                {item.tag && (
                                  <div className="most-popular">{item.tag}</div>
                                )}
                              </CardTitle>
                              <Row className="card-wrapper-desc">
                                <Col md="12" className="header1">
                                  <Label>
                                    {(item.firstLine || false) &&
                                      item.firstLine}
                                  </Label>
                                </Col>
                                <Col md="12">
                                  <Label className="bullet">
                                    {(item.secondLine || false) &&
                                      item.secondLine}
                                  </Label>
                                </Col>
                              </Row>
                              <Row className="card-wrapper-bullets">
                                <Col md="12">
                                  {item.features?.map(
                                    (feature: ProductFeatureVM) => (
                                      <div key={feature.id}>
                                        <Label className="bullet">
                                          <FontAwesomeIcon
                                            icon={Icon.faCheck}
                                          ></FontAwesomeIcon>
                                          &nbsp;&nbsp;{feature.name}
                                        </Label>
                                      </div>
                                    )
                                  )}
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <hr className="split-line"></hr>
                                </Col>
                              </Row>
                              <Row>
                                {item.displayAdditionalPricingInformation ? (
                                  <Fragment>
                                    <Col md="7" lg="7" xs="6">
                                      <span>{item.totalAfterTrialLabel}</span>
                                    </Col>
                                    <Col
                                      md="5"
                                      lg="5"
                                      xs="6"
                                      className="text-right"
                                    >
                                      <span>&nbsp;&nbsp;&nbsp;</span>
                                      <span>{item.totalAfterTrialValue}</span>
                                    </Col>
                                  </Fragment>
                                ) : (
                                  <Fragment>
                                    <Col>&nbsp;</Col>
                                  </Fragment>
                                )}
                              </Row>
                              <Row>
                                <Col
                                  md="7"
                                  lg="7"
                                  xs="6"
                                  className="font-weight-bold"
                                >
                                  <span>Total due today</span>
                                </Col>
                                <Col
                                  md="5"
                                  lg="5"
                                  xs="6"
                                  className="text-right font-weight-bold"
                                >
                                  <span>&nbsp;&nbsp;&nbsp;</span>
                                  <span>
                                    $
                                    {(item.price || 0) > 0 && !item.isFreeTrial
                                      ? Helper.formatMoney(
                                          item.price / 100,
                                          2,
                                          ".",
                                          ","
                                        )
                                      : "0.00"}
                                  </span>
                                </Col>
                              </Row>
                            </Card>
                          </div>
                        </Fragment>
                      ))}
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col md="8" className="pt-3">
              <Row className="justify-content-center">
                <Col className="text-right" md="5">
                  <SkinnedButton
                    className={`full-width ${
                      serverErrors ? "SubmitButton--error" : ""
                    }`}
                    color="primary"
                    disabled={processing || isAdmin}
                  >
                    Continue
                  </SkinnedButton>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </LoadingSection>
    </Container>
  );
};
